
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

interface Exercise {
  subject: string;
  title: string;
  module: string;
  desc: string;
  deadline: string;
  marked: boolean;
  resources: File[];
  trimester: string;
  status: string;
}

interface File {
  name: string;
  url: string;
  storedName: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

export default defineComponent({
  name: "edit-exercise",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    widgetClasses: String,
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // eslint-disable-next-line
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const uploadPercentage = ref(0);

    const homeworkID = route.params.hid;
    const classID = route.params.id;

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const exercise = ref<Exercise>({
      subject: "",
      title: "",
      module: "",
      desc: "",
      deadline: "",
      marked: true,
      resources: [],
      trimester,
      status: "",
    });

    const studentList = ref<any[]>([]);
    const getStudents = (students: []) => {
      const sy = window.localStorage.getItem("activeSchoolarYear");
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = classID;
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data.filter((s) =>
            students.find((id) => id == s._id)
          );
        })
        .catch((e) => console.log(e));
    };

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
    });

    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileUrl = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    ApiService.post("/teacher/classRommDetails", {
      query: {
        teacher: store.getters.currentUser._id,
        trimester: trimester,
      },
    })
      .then(({ data }) => {
        subjects.value = [];
        modules.value = [];
        const classroom = data.find(
          (el) => el.classRoom._id == route.params.id
        );
        if (classroom) {
          subjects.value = classroom.subjects;
        }

        //remove duplicated
        subjects.value = subjects.value.filter(
          (subject, index) =>
            subjects.value.findIndex((s) => s._id == subject._id) === index &&
            !modules.value.find((m) =>
              m.subjects.find((ss) => ss._id == subject._id)
            ) &&
            subject.status !== "inactive"
        );
      })
      .catch((e) => console.log(e));

    ApiService.get(`/lms/homework/${homeworkID}`)
      .then(({ data }) => {
        exercise.value = {
          subject: data.subject._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          marked: data.marked,
          deadline: data.deadline,
          resources: [],
          trimester: data.trimester ? data.trimester : "0",
          status: data.status,
        };

        data.resources.forEach((file) => {
          exercise.value.resources.push({
            storedName: file,
            name: getFileName(file),
            url: getFileUrl(file),
          });
        });

        if (data.students && data.students.length != 0)
          getStudents(data.students);

        if (data.status == "validated") router.push("/404");
      })
      .catch((e) => {
        console.log(e);
      });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      const data = new FormData();

      for (const name in exercise.value) {
        if (!exercise.value.deadline) exercise.value.deadline = "";
        if (!["status", "resources"].includes(name))
          data.append(name, exercise.value[name]);
      }

      if (uploadFiles.value.length > 0) {
        uploadFiles.value.forEach((file) => {
          if (file.raw) data.append("resources", file.raw, file.raw.name);
          else data.append("resources[]", file.storedName);
        });
      } else {
        exercise.value.resources.map((file) => {
          data.append("resources[]", file.storedName);
        });
      }

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.patch(
        `/lms/homework/${homeworkID}`,
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(() => {
          event("Edit exercice", {
            event_category: "Exercice",
            event_label: "Classe profile",
            value: 1,
          });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.editedExerciseInfo"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("course.okay"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/classe/${classID}/exerciceDetails/${homeworkID}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    return {
      t,
      classID,
      homeworkID,
      subjects,
      upload,
      exercise,
      modules,
      validationSchema,
      submit,
      submitButtonRef,
      studentList,
      updateUploadFiles,
      handleExceed,
      uploadPercentage,
      customColorMethod,
    };
  },
});
